/*login*/
:root {
  --color-corporativo: #3fbf79;
  --color-corporativo2: white;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  --color-button: #fdffff;
  --color-black: rgba(0, 0, 0, 0.4);

}

.titulo-pagina {
  color: var(--color-corporativo2) !important;
}

body {
  background-image: url("../Imagenes/Fondo.png");
  background-size: cover;
  min-height: calc(100vh);

}

.iconos {
  color: #3fbf79 !important;
}

.form-group,
.form-group2,
.label-RemenberPassword,
.cambiar-user,
.link-recordar,
.Navbar-Logotipo,
.info-contraseña,
.formulario-contraseña,
.acerca,
.version,
.email,
.select-telefonos,
.detalles-grafico-accion,
.detalles-grafico,
.operadores-text,
.lista,
.activar-roaming,
.Roaming,
.nombre-tarifa-nom,
.modal-header-tarifas,
.btn-rojo-xl-tarifas,
.usuario-guardado {

  color: white !important;
}

.btn-rojo-xl-tarifas .icon-black {
  color: white !important;
}

.usuario-guardado {
  text-align: center;

}

.Navbar-Logotipo .icon {
  color: white !important;
}

.div-buscar .icon {
  color: white !important;
}


.detalles-grafico a {
  color: white !important;
}

.detalles-grafico-accion a .icon-btn {
  color: white !important;
}

.detalles-grafico-accion .btn-cerrar {
  color: white !important;
}

.version .icon {
  color: white !important;
}

.email a {
  color: white !important;
}

.email .icon {
  color: white !important;
}

.tlf a {
  color: white !important;
}

.tlf .icon {
  color: white !important;
}


.button-inner {
  color: white !important;
}

.link-recordar {
  line-height: 17px;
  width: 58%;
}

.links {
  margin-right: -50px;
}


.espacio {
  margin-left: 5px;
}

.importe-factura a {
  color: black !important;
  font-weight: 900;
}


.cambiar-user {
  line-height: 17px;
  width: 70%;
  margin-left: 19px;
}

.iconos-titulo {
  margin-bottom: -4px;
  padding-right: 5px;
}

/*body {
  background: #c6e2e5;
}*/

.Logotipo {
  display: block;
  width: 82%;
  height: auto;
  max-width: 400px;
  margin: auto;
  margin-top: 55px;
  margin-bottom: 28px;
}

.LogotipoHome {
  display: block;
  width: 75%;
  height: auto;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 0%;
}

.form-login {
  margin-top: 60px;
}


.btn-inicio {
  margin-top: 30px !important;
  background: #f3e8e8 !important;
  border: 1px solid #f3e8e8 !important;
  color: black !important
}

.form {
  margin-top: 40px !important;
}

.form-group {
  margin-top: 15px !important;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px solid #c8c7cc;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-right: 8px;
}

.cambiar-user {
  margin-bottom: 15px !important;
}

.label-RemenberPassword {
  margin-left: 15px;
  position: relative !important;
  bottom: 14px !important;
  left: 0px !important;
}


.icono-linea-sms:focus,
.icono-linea-sms:active,
.icono-linea-sms:visited,
.icono-linea-sms:hover,
.icono-linea-sms:focus-visible {
  border: 1px solid #005fa6 !important;
  border-radius: 20px;
  outline: none;
}

.icono-linea-llama:focus,
.icono-linea-llama:active,
.icono-linea-llama:visited,
.icono-linea-llama:hover,
.icono-linea-llama:focus-visible {
  border: 1px solid #8fb523 !important;
  border-radius: 20px;
  outline: none;
}

.icono-linea-gb:focus,
.icono-linea-gb:active,
.icono-linea-gb:visited,
.icono-linea-gb:hover,
.icono-linea-gb:focus-visible {
  border: 1px solid #fc6424 !important;
  border-radius: 20px;
  outline: none;
}

.num-contrato,
.tarifa,
.descarga {
  color: black !important;
  font-weight: 900;
}

.dettalles-contrato {
  background: white !important;
}

ul.list-unstyled li {
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 8px;
  margin-top: 5px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--color-corporativo);
  border-color: var(--color-corporativo);
}

.modal-header-puk .icon-black-tarifas {
  color: black !important;
}



.banderas button {
  width: 30% !important;
}

.container-banderas {
  display: flex;
  justify-content: center;
}

.banderas {
  background-color: #f3e8e8 !important;
  color: black;
  margin-left: 5px;
  width: 30% !important;
  border-radius: 3px;
}

.div-home,
.div-home-cerrar,
ul.list-unstyled li,
.div-tarifas,
.herramientas-list-sim,
.herramientas-list {
  box-shadow: none !important;
  background: #f3e8e8 !important;

}

.btn-rojo-xl,
.consumo {
  background: #f3e8e8 !important;
}